import $ from 'jquery'

export default function initEitherOr() {
	$('.js-either-or')
		.each(function () {
			const $this = $(this)

			$this.data('orig', $this.val())
		})

		.on('change keyup', function () {
			const $this = $(this)
			const $cont = $this.closest('.js-either-or-container')
			const $other = $('.js-either-or', $cont).not($this)

			$other.prop('disabled', $this.val() !== $this.data('orig'))
		})
}
