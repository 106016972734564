import { defineComponent, type PropType } from 'vue'

import TreeView, {
	type CheckedNodes,
	type Folder,
	type NodeStates,
} from '@/javascript/vuejs/components/shared/TreeView'
import { on } from '@/javascript/vuejs/helpers/emitEventHandler'
import type { ContentPackageFolder, PackageType } from '@/javascript/vuejs/services/packages/interfaces'

import { toFolder } from './utils'

/* eslint-disable @typescript-eslint/no-unused-vars */
const emits = {
	action: (action: string, id?: number | string | undefined, type?: PackageType): boolean => true,
	bulk: (checkedNodes: CheckedNodes[]): boolean => true,
}
/* eslint-enable */

export default defineComponent({
	name: 'FolderViewPackage',

	props: {
		...on(emits),

		folders: {
			required: true,
			type: Array as PropType<ContentPackageFolder[]>,
		},
	},

	emits,

	data(): Data {
		return {
			folderState: {
				checked: false,
				visible: true,
			},
		}
	},

	computed: {
		contentPackageFolders(): Folder[] {
			return this.folders.map(toFolder)
		},
	},

	render() {
		return (
			<div class="packages packages__folder_view">
				<div class="packages__container">
					<div class="packages__data-table">
						<TreeView
							additionalClasses="tree__content-packages"
							autoCheckChildren={false}
							checkbox={true}
							data={this.contentPackageFolders}
							dataTest="tree-view-content-packages"
							message="No content package folders"
							onAction={(action: string, id: number, type: PackageType): void => this.$emit('action', action, id, type)}
							onBulk={(checkedNodes: CheckedNodes[]): void => this.$emit('bulk', checkedNodes)}
						/>
					</div>
				</div>
			</div>
		)
	},
})

interface Data {
	folderState: NodeStates
}
