import $ from 'jquery'

export default function initImageUpload() {
	// Image preview
	function generateFilePreview(previewId, input) {
		if (input.files && input.files[0]) {
			const reader = new FileReader()

			reader.onload = function (data) {
				$(previewId).attr('src', data.target.result)
			}
			reader.readAsDataURL(input.files[0])
		}
	}

	$('#upload_image').on('click', function (event) {
		event.preventDefault()
		$('#files').click()
		$('#upload_image').blur()
	})

	$('#files').on('change', function () {
		generateFilePreview('#logo_preview', this)
	})
}
