import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/collapse'

export default function bootstrapNavbar() {
	// Auto-activate the current page's nav link
	const currentPath = window.location.pathname
	const navLinks = document.querySelectorAll(`a[href='${currentPath}']`)

	navLinks.forEach(navLink => {
		navLink.classList.add('active')

		const navLinkParent = navLink.closest('.nav-item.dropdown')

		if (navLinkParent) {
			navLinkParent.classList.add('active')
		}
	})
}
