import { CAButton } from '@careacademy/ca-design-system'
import { defineComponent } from 'vue'

import { on } from '@/javascript/vuejs/helpers/emitEventHandler'

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const emits = { show: (value: 'folder' | 'list'): boolean => true }

export default defineComponent({
	name: 'ListViewFolderView',

	props: { ...on(emits) },

	emits,

	render() {
		return (
			<p>
				<CAButton
					buttonStyle="filled-light"
					class="mr-2"
					dataTest="folder-view-link"
					onClick={(): void => this.$emit('show', 'folder')}
				>
					Folder View
				</CAButton>

				<CAButton
					buttonStyle="filled-light"
					dataTest="list-view-link"
					onClick={(): void => this.$emit('show', 'list')}
				>
					List View
				</CAButton>
			</p>
		)
	},
})
