export default function caregiverDashboardEnabledCheckbox(): void {
	const pricingTierField = document.getElementById('agency_pricing_tier') as HTMLInputElement
	const caregiverDashboardEnabledCheckbox = document.getElementById('agency_caregiver_dashboard_enabled') as HTMLInputElement

	if (!pricingTierField || !caregiverDashboardEnabledCheckbox) {
		return
	} else {
		pricingTierField.addEventListener('change', (): void => {
			if (pricingTierField.value === 'legacy') {
				caregiverDashboardEnabledCheckbox.checked = false
			} else {
				caregiverDashboardEnabledCheckbox.checked = true
			}
		})
	}
}
