import {
	CAButton,
	CAInput,
	CAModal,
} from '@careacademy/ca-design-system'
import { defineComponent } from 'vue'

import { on } from '@/javascript/vuejs/helpers/emitEventHandler'
import api from '@/javascript/vuejs/services/api'
import { createFolder } from '@/javascript/vuejs/services/packages'
import GlobalStore from '@/javascript/vuejs/stores/GlobalStore'

const emits = { created: (): boolean => true }

export default defineComponent({
	name: 'CreateFolder',

	props: {
		...on(emits),

		parentFolderId: { optional: true, type: Number },
	},

	emits,

	data(): Data {
		return {
			createFolderName: '',
			isProcessing: false,
		}
	},

	computed: {
		isDisabled(): boolean {
			return !this.createFolderName
		},

		modalName(): string {
			return 'create-content-package-folder-modal'
		},
	},

	methods: {
		closeModal(): void {
			GlobalStore.resetCAModal()
		},

		async createContentPackageFolder(): Promise<void> {
			if (!this.createFolderName) {
				return
			}

			this.isProcessing = true
			try {
				await createFolder(api, {
					name: this.createFolderName,
					parentContentPackageFolderId: this.parentFolderId ?? null,
				})

				GlobalStore.onHandleSuccessMessages({ message: `Content package folder, named "${this.createFolderName}" created` })

				this.closeModal()
				this.$emit('created')
				this.createFolderName = ''
			} catch (error: any) {
				GlobalStore.onHandleErrorMessages({ error: error.message })
			} finally {
				this.isProcessing = false
			}
		},

		showModal(): void {
			GlobalStore.emittedEvent = this.modalName
		},
	},

	render() {
		return (
			<div>
				<CAButton
					buttonStyle="filled-light"
					dataTest="content-packages-new-folder"
					loading={this.isProcessing}
					onClick={this.showModal}
				>
					Create package folder
				</CAButton>

				<CAModal
					alignment="left-aligned"
					disabled={this.isDisabled}
					isOpen={GlobalStore.emittedEvent === this.modalName}
					submitText="Create"
					title="Create package folder"
					onCancel={this.closeModal}
					onSubmit={this.createContentPackageFolder}
				>
					<CAInput
						autofocus={true}
						dataTest="create-new-content-package-folder"
						label="Folder Name"
						labelAppearance="static"
						required={true}
						v-model={this.createFolderName}
						onEnter={this.createContentPackageFolder}
					/>
				</CAModal>
			</div>
		)
	},
})

interface Data {
	createFolderName: string
	isProcessing: boolean
}
