import { CANotification } from '@careacademy/ca-design-system'
import Vue from 'vue'

export default function notificationWrapperApp(): void {
	new Vue({
		el: '#notificationWrapperApp',

		name: 'NotificationWrapper',

		components: { 'ca-notification': CANotification },
	})
}