import { CAModal, CASelect } from '@careacademy/ca-design-system'
import { defineComponent, type PropType } from 'vue'

import type { CheckedNodes } from '@/javascript/vuejs/components/shared/TreeView'
import { on } from '@/javascript/vuejs/helpers/emitEventHandler'
import api from '@/javascript/vuejs/services/api'
import { moveContentPackageOrFolder } from '@/javascript/vuejs/services/packages/'
import type { ContentPackageFolder } from '@/javascript/vuejs/services/packages/interfaces'
import GlobalStore from '@/javascript/vuejs/stores/GlobalStore'
import PackagesAndProgramsStore from '@/javascript/vuejs/stores/PackagesAndProgramsStore'

/* eslint-disable @typescript-eslint/no-unused-vars */
const emits = {
	cancel: (): boolean => true,
	move: (id: number | null): boolean => true,
}
/* eslint-enable */

export default defineComponent({
	name: 'MoveIntoFolderModal',

	props: {
		...on(emits),

		folders: {
			required: true,
			type: Array as PropType<ContentPackageFolder[]>,
		},

		items: {
			required: true,
			type: Array as PropType<CheckedNodes[]>,
		},
	},

	emits,

	data(): Data {
		return { destinationFolderId: null }
	},

	methods: {
		closeModal(): void {
			GlobalStore.resetCAModal()
			this.destinationFolderId = null
			this.$emit('cancel')
		},

		async moveIntoFolder(): Promise<void> {
			if (!this.items.length) {
				GlobalStore.onHandleErrorMessages({ error: 'Nothing to move. Please select item(s) and try again' })

				return
			}

			try {
				await moveContentPackageOrFolder(api, {
					id: this.destinationFolderId,
					items: this.items,
				})
				this.$emit('move', this.destinationFolderId)
				this.closeModal()
				GlobalStore.onHandleSuccessMessages({ message: 'Selected content package(s)/folder(s) have been moved' })
			} catch (error: any) {
				GlobalStore.onHandleErrorMessages({ error: error.message })
			}
		},
	},

	render() {
		return (
			<CAModal
				alignment="left-aligned"
				isOpen={GlobalStore.emittedEvent === 'move-into-folder-modal'}
				submitText="Move"
				title="Move into folder"
				onCancel={this.closeModal}
				onSubmit={this.moveIntoFolder}
			>
				<CASelect
					dataTest="destination-folder-select"
					id="destination-folder-id"
					label="Select folder to move package(s) or folder(s)"
					options={PackagesAndProgramsStore.allFolders.map(folder => ({
						id: folder.id,
						label: folder.level_name ?? folder.name,
					}))}
					required={true}
					v-model={this.destinationFolderId}
				/>
			</CAModal>
		)
	},
})

interface Data {
	destinationFolderId: number | null
}
