export default function requirePublicNameForVisibleCurriculum(): void {
	const visible = document.getElementById('state_requirement_customer_visible') as HTMLInputElement
	const publicName = document.getElementById('state_requirement_public_name') as HTMLInputElement

	if (!visible || !publicName) {
		return
	} else {
		visible.addEventListener('change', (): void => {
			if (visible.checked) {
				publicName.required = true
			} else {
				publicName.required = false
			}
		})
	}
}
