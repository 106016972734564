import { CANotification } from '@careacademy/ca-design-system'
import humps from 'humps'
import Vue from 'vue'

import type { Cell, RowData } from '../components/shared/DataTable'
import MoveDataBetweenTables from '../components/shared/MoveDataBetweenTables'
import { setSelectedRows } from '../components/shared/utils'
import PackagesAndProgramsStore from '../stores/PackagesAndProgramsStore'

export default function packageManagementApp(): void {
	new Vue({
		el: '#packageManagementApp',

		name: 'PackageManagementPage',

		components: {
			'ca-notification': CANotification,
			'move-data-between-tables': MoveDataBetweenTables,
		},

		data(): Data {
			return {
				cellDataArray: [
					{ title: { text: 'name' } },
					{ title: { text: 'sku' } },
					{ title: { text: 'portalName' } },
				],

				columns: [
					{
						ariaLabel: 'Sort by Name',
						columnId: 'name',
						isSortable: true,
						name: 'Name',
					},
					{
						ariaLabel: 'Sort by Sku',
						columnId: 'sku',
						isSortable: true,
						name: 'Sku',
					},
					{
						ariaLabel: 'Portal',
						columnId: 'portal',
						isSortable: true,
						name: 'Portal',
					},
				],

				courses: [],
				currentPackage: null,
				rows: [],
				selectedRows: [] as RowData[],
			}
		},

		mounted(): void {
			const configElement = document.getElementById('package_management_vue_config')

			if (configElement) {
				const config: any = humps.camelizeKeys(JSON.parse(configElement.innerHTML))

				this.courses = config.courses
				this.currentPackage = config.package

				// Placing values in the store for future uses/references
				PackagesAndProgramsStore.courses = config.courses
				// PackagesAndProgramsStore.currentPackage = config.package
			}

			this.rows = this.courses.map(course => ({
				cells: this.dataToCellDataArray(course),
				rowId: `${course.id}`,
			}))

			this.selectedRows = setSelectedRows('package_courses', this.rows)
		},

		methods: {
			dataToCellDataArray(course: Course): Cell[] {
				return [
					{
						title: {
							dataTest: course.name,
							text: course.name,
						},
					},
					{ title: { text: course.sku ?? '' } },
					{ title: { text: course.portalName ?? '' } },
				]
			},

			updateSelectedRows(data: RowData[]): void {
				this.selectedRows.push(...data)
			},

			removeFromSelectedRows(data: RowData[]): void {
				data.forEach(row => {
					const index = this.selectedRows.findIndex(selectedRow => selectedRow.rowId === row.rowId)

					if (index > -1) {
						this.selectedRows.splice(index, 1)
					}
				})
			},
		},
	})
}

interface Course {
	id: number
	name: string
	portalName: string
	sku: string
}

interface CellData {
	title: {
		dataTest?: string
		text: string
	}
}

interface Data {
	cellDataArray: CellData[]
	columns: Column[]
	courses: Course[]
	currentPackage: any
	rows: RowData[]
	selectedRows: RowData[]
}

interface Column {
	ariaLabel: string
	columnId: string
	isSortable: boolean
	name: string
}
