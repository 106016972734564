export default function showPortalMigrationStatus(): void {
	const displayStatus = document.getElementById('show_portal_migration_status') as HTMLButtonElement

	if (!displayStatus) {
		return
	}

	displayStatus.addEventListener('click', (): void => {
		const status = document.getElementById('portal_migration_status_info') as HTMLDivElement

		if (!status) {
			return
		}

		if (status.style.display === 'none' || status.style.display === '') {
			status.style.display = 'block'

			return
		} else {
			status.style.display = 'none'

			return
		}
	})
}
