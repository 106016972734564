import type { RowData } from '@/javascript/vuejs/components/shared/DataTable'

export function setSelectedRows(inputId: string, rowData: RowData[]): RowData[] {
	const inputElement = document.getElementById(inputId) as HTMLSelectElement

	if (inputElement) {
		const options = inputElement.selectedOptions
		const domOptions = Array.from(options)
		const selectedOptions = domOptions.map(option => ({
			text: option.text,
			value: option.value,
		}))

		const selectedDataRows: RowData[] = []

		for (const i of selectedOptions) {
			const item = rowData.find(x => x.rowId === i.value)

			if (item) {
				selectedDataRows.push(item)
			}
		}

		return selectedDataRows
	} else {
		return rowData
	}
}
