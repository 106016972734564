import { CAInput, CAModal } from '@careacademy/ca-design-system'
import { defineComponent, type PropType } from 'vue'

import { on } from '@/javascript/vuejs/helpers/emitEventHandler'
import api from '@/javascript/vuejs/services/api'
import { renameContentPackageFolder } from '@/javascript/vuejs/services/packages/'
import type { ContentPackageFolder } from '@/javascript/vuejs/services/packages/interfaces'
import GlobalStore from '@/javascript/vuejs/stores/GlobalStore'

import { stringToNumber } from '../utils'
/* eslint-disable @typescript-eslint/no-unused-vars */
const emits = {
	cancel: (): boolean => true,
	renamed: (id: number, value: string): boolean => true,
}
/* eslint-enable */

export default defineComponent({
	name: 'RenameFolder',

	props: {
		...on(emits),

		folder: {
			required: true,
			type: Object as PropType<ContentPackageFolder>,
		},
	},

	emits,

	data(): Data {
		return {
			isProcessing: false,
			renameFolderName: '',
		}
	},

	computed: {
		isDisabled(): boolean {
			return !this.renameFolderName
		},

		modalName(): string {
			return 'rename-content-package-folder-modal'
		},
	},

	mounted() {
		this.renameFolderName = this.folder.name
	},

	methods: {
		closeModal(): void {
			GlobalStore.resetCAModal()
			this.renameFolderName = ''
			this.$emit('cancel')
		},

		async renameContentPackageFolder(): Promise<void> {
			if (!this.renameFolderName) {
				return
			}

			const folderId = typeof this.folder.id === 'string' ? stringToNumber(this.folder.id) : this.folder.id

			this.isProcessing = true

			if (folderId) {
				try {
					await renameContentPackageFolder(api, {
						id: folderId,
						name: this.renameFolderName,
					})
					this.$emit('renamed', folderId, this.renameFolderName)
					GlobalStore.onHandleSuccessMessages({ message: `"${this.folder.name}" renamed to "${this.renameFolderName}"` })
					this.closeModal()
				} catch (error: any) {
					GlobalStore.onHandleErrorMessages({ error: error.message })
				} finally {
					this.isProcessing = false
				}
			}
		},
	},

	render() {
		return (
			<CAModal
				alignment="left-aligned"
				dataTest={this.modalName}
				disabled={this.isDisabled}
				isOpen={GlobalStore.emittedEvent === this.modalName}
				submitText="Rename"
				title={`Rename ${this.folder.name}`}
				onCancel={this.closeModal}
				onSubmit={this.renameContentPackageFolder}
			>
				<CAInput
					autoFocus={true}
					class="mb-8"
					dataTest="rename-content-package-folder"
					id="renameFolder"
					inputRef="renameFolderId"
					label="Folder name"
					labelAppearance="static"
					required={true}
					v-model={this.renameFolderName}
					onEnter={this.renameContentPackageFolder}
				/>
			</CAModal>
		)
	},
})

interface Data {
	isProcessing: boolean
	renameFolderName: string
}
