// For Admin CSR pages and related data

import Vue from 'vue'

import type { Course } from '@/javascript/interfaces'

import api from '../services/api'
import { getContentPackageFolderList } from '../services/packages'
import type { ContentPackage, ContentPackageFolder } from '../services/packages/interfaces'
import type { Certification, Curriculum } from '../services/trainingCurriculums/interfaces'

const state = Vue.observable({
	allAvailablePackagesAndFolders: [] as ContentPackageFolder[],
	allFolders: [] as ContentPackageFolder[],
	certifications: [] as Certification[],
	contentPackages: [] as ContentPackage[],
	courses: [] as Course[],
	curriculums: [] as Curriculum[],
	selectedFolder: {} as ContentPackageFolder | undefined,
})

const store = {
	get allAvailablePackagesAndFolders(): ContentPackageFolder[] {
		return state.allAvailablePackagesAndFolders
	},
	set allAvailablePackagesAndFolders(value) {
		state.allAvailablePackagesAndFolders = value
	},

	get allFolders(): ContentPackageFolder[] {
		return state.allFolders
	},
	set allFolders(value) {
		state.allFolders = value
	},

	get certifications(): Certification[] {
		return state.certifications
	},
	set certifications(value) {
		state.certifications = value
	},

	get contentPackages(): ContentPackage[] {
		return state.contentPackages
	},
	set contentPackages(value) {
		state.contentPackages = value
	},

	get courses(): Course[] {
		return state.courses
	},
	set courses(value) {
		state.courses = value
	},

	get curriculums(): Curriculum[] {
		return state.curriculums
	},
	set curriculums(value) {
		state.curriculums = value
	},

	get selectedFolder(): ContentPackageFolder | undefined {
		return state.selectedFolder
	},
	set selectedFolder(value) {
		state.selectedFolder = value
	},

	// Actions
	async getAllContentPackageFolders(): Promise<void> {
		try {
			const response = await getContentPackageFolderList(api)

			state.allFolders = response
		} catch (error) {
			console.error(error)
		}
	},
}

export default store
