import $ from 'jquery'

export default function initMultiSelector() {
	// Todo: figure out how to generalize this
	$('#course_topics').on('change', function () {
		const $topicsSelected = $('#selected_topics')

		$topicsSelected.html(
			$('#course_topics option:selected')
				.map(function () {
					return this.text
				})
				.get()
				.join(', ')
		)
	})

	$('#agency_back_office_locations').on('change', function () {
		const $backOfficeLocationsSelected = $('#selected_back_office_locations')

		$backOfficeLocationsSelected.html(
			$('#agency_back_office_locations option:selected')
				.map(function () {
					return this.text
				})
				.get()
				.join(', ')
		)
	})

	$('#course_agencies').on('change', function () {
		const $agenciesSelected = $('#selected_agencies')

		$agenciesSelected.html(
			$('#course_agencies option:selected')
				.map(function () {
					return this.text
				})
				.get()
				.join(', ')
		)
	})

	$('#course_franchisors').on('change', function () {
		const $franchisorsSelected = $('#selected_franchisors')

		$franchisorsSelected.html(
			$('#course_franchisors option:selected')
				.map(function () {
					return this.text
				})
				.get()
				.join(', ')
		)
	})

	$('#franchisor_back_office_caregiver_groups').on('change', function () {
		const $franchisorsSelected = $('#selected_back_office_caregiver_groups')

		$franchisorsSelected.html(
			$('#franchisor_back_office_caregiver_groups option:selected')
				.map(function () {
					return this.text
				})
				.get()
				.join(', ')
		)
	})

	$('#topic_states_to_include').on('change', function () {
		const $selected = $('#selected_states_to_include')

		$selected.html(
			$('#topic_states_to_include option:selected')
				.map(function () {
					return this.text
				})
				.get()
				.join(', ')
		)
	})

	$('#topic_states_to_omit').on('change', function () {
		const $selected = $('#selected_states_to_omit')

		$selected.html(
			$('#topic_states_to_omit option:selected')
				.map(function () {
					return this.text
				})
				.get()
				.join(', ')
		)
	})
}
