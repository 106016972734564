import api from '@/javascript/vuejs/services/api'

export default function portalMigrationToMainPortal(): void {
	const configElement = document.getElementById('portal_migration_to_main_portal_data')
	const startPortalMigration = document.getElementById('start_portal_migration_to_main_portal')

	if (!configElement || !startPortalMigration) {
		return
	}

	const config = JSON.parse(configElement.innerHTML)

	startPortalMigration.addEventListener('click', (): void => {
		displayStartMigration('none')
		if (!confirm('Are you sure you want to migrate this agency\'s LU sub portal to the main portal?')) {
			setPortalMigrationStatus('')

			return
		}
		api.post(config.start_migration_path, {})
			.then(response => {
				const data = response.data as Data

				setPortalMigrationStatus(data.message)
			})
			.catch(error => {
				const response = error.response
				const data = response.data

				setPortalMigrationStatus(data.message)
			})
	})
}

function setPortalMigrationStatus(message: string): void {
	const portalMigrationStatus = document.getElementById('portal_migration_status_for_main_portal')

	if (portalMigrationStatus) {
		portalMigrationStatus.innerHTML = message
	}
}

function displayStartMigration(style: string): void {
	const element = document.getElementById('start_portal_migration_to_main_portal')

	if (element) { element.style.display = style }
}

interface Data {
	errors: string[]
	message: string
	next_step_scheduled_at_datetime: string
	status_name: string
}
