import { CANotification } from '@careacademy/ca-design-system'
import Vue from 'vue'

import BugSnagVuePlugin from '../plugins/bugsnag'
BugSnagVuePlugin()

import ContentPackages from '../components/packages/ContentPackages'

export default function contentPackagesApp(): void {
	new Vue({
		el: '#contentPackages',

		name: 'ContentPackagesPage',

		components: {
			'ca-notification': CANotification,
			'content-packages': ContentPackages,
		},
	})
}
