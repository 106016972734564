import { defineComponent } from 'vue'

import DataTable, {
	type Cell,
	type TableData,
} from '@/javascript/vuejs/components/shared/DataTable'
import { on } from '@/javascript/vuejs/helpers/emitEventHandler'
import { CONTENT_PACKAGE, type ContentPackage } from '@/javascript/vuejs/services/packages/interfaces'
import PackagesAndProgramsStore from '@/javascript/vuejs/stores/PackagesAndProgramsStore'

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const emits = { action: (action: string, id?: number | string | undefined): boolean => true }

export default defineComponent({
	name: 'ListViewPackage',

	props: { ...on(emits) },

	emits,

	data(): Data {
		return {
			packages: {
				columns: [
					{
						ariaLabel: 'Sort by Name',
						columnId: 'name',
						isSortable: true,
						name: 'Name',
						width: '30%',
					},
					{
						ariaLabel: 'Cumulative Courses',
						columnId: 'cumulative_courses',
						name: 'Cumulative Courses',
					},
					{
						ariaLabel: 'Sub Package',
						columnId: 'sub_package',
						name: 'Sub Package',
						width: '30%',
					},
					{
						ariaLabel: 'Certifications',
						columnId: 'certifications',
						name: 'Certifications',
					},
					{
						ariaLabel: 'Assigned Courses',
						columnId: 'assigned_courses',
						name: 'Assigned Courses',
					},
					{
						ariaLabel: 'Hours',
						columnId: 'hours',
						name: 'Hours',
						width: '20%',
					},
					{
						ariaLabel: 'Building Blocks',
						columnId: 'building_blocks',
						name: 'Building Blocks?',
					},
					{
						ariaLabel: 'Content Package Actions',
						columnId: 'content_package_actions',
						name: '',
						width: '150px',
					},
				],

				rows: [],
			},
		}
	},

	computed: {
		contentPackages(): TableData {
			return {
				columns: this.packages.columns,
				rows: PackagesAndProgramsStore.contentPackages.map((p: ContentPackage) => ({
					actions: [
						{
							dataTest: 'package-action-edit',
							icon: 'fa-edit-outline',
							id: p.id,
							link: p.editPath,
							text: 'Edit',
							tooltip: 'Edit Package',
							type: CONTENT_PACKAGE,
						},
						{
							dataTest: 'package-action-copy',
							icon: 'fa-file-text',
							id: p.id,
							link: p.copyPath,
							text: 'Copy',
							tooltip: 'Copy Package',
							type: CONTENT_PACKAGE,
						},
					],
					cells: this.packageToCellDataArray(p),
					rowId: `${p.id}`,
				})),
			}
		},
	},

	methods: {
		packageToCellDataArray(p: ContentPackage): Cell[] {
			return [
				{ title: { text: p.name } },
				{ title: { text: `${p.cumulativeCourses}` } },
				{ title: { text: p.subPackages } },
				{ title: { text: `${p.certifications}` } },
				{ title: { text: `${p.assignedCourses}` } },
				{ title: { text: p.hours ?? '' } },
				{ title: { text: !!p.isBuildingBlock ? 'Yes' : 'No' } },
			]
		},
	},

	render() {
		return (
			<div class="packages packages__list_view">
				<div class="packages__container">
					<div class="packages__data-table">
						<DataTable
							data={this.contentPackages}
							dataTest="data-table-content-packages"
							isStickyHeader={true}
							itemsPerPage={20}
							message="No content packages"
							showCheckboxes={false}
							showPagination={true}
							sortable={true}
							onAction={(action: string, id?: number | string | undefined): void => this.$emit('action', action, id)}
						/>
					</div>
				</div>
			</div>
		)
	},
})

interface Data {
	packages: TableData
}
