import { CANotification } from '@careacademy/ca-design-system'
import humps from 'humps'
import Vue from 'vue'

import type { Course } from '@/javascript/interfaces'

import type {
	Cell,
	ColumnData,
	RowData,
} from '../components/shared/DataTable'
import MoveDataBetweenTables from '../components/shared/MoveDataBetweenTables'
import { setSelectedRows } from '../components/shared/utils'
import { releasedAtStatusText } from '../components/training_curriculums/utils'
import type { Certification, Curriculum } from '../services/trainingCurriculums/interfaces'
import PackagesAndProgramsStore from '../stores/PackagesAndProgramsStore'

export default function programManagementApp(): void {
	new Vue({
		el: '#programManagementApp',

		name: 'ProgramManagementPage',

		components: {
			'ca-notification': CANotification,
			'move-data-between-tables': MoveDataBetweenTables,
		},

		data(): Data {
			return {
				PackagesAndProgramsStore,
				cellCertificationDataArray: [
					'name',
					'pricing_tier',
					'released_at',
				],

				cellCourseDataArray: [
					'name',
					'sku',
					'portalName',
				],

				cellCurriculumDataArray: ['name', 'payer_source'],
				certificationRows: [],
				certifications: [],
				columnCertifications: [
					{
						ariaLabel: 'Sort by Name',
						columnId: 'name',
						isSortable: true,
						name: 'Name',
						width: '300px',
					},
					{
						ariaLabel: 'Sort by Pricing Tier',
						columnId: 'pricing_tier',
						isSortable: true,
						name: 'Tier',
					},
					{
						ariaLabel: 'Sort by Released',
						columnId: 'released_at',
						isSortable: true,
						name: 'Released',
					},
				],

				columnCourses: [
					{
						ariaLabel: 'Sort by Name',
						columnId: 'name',
						isSortable: true,
						name: 'Name',
					},
					{
						ariaLabel: 'Sort by Sku',
						columnId: 'sku',
						isSortable: true,
						name: 'Sku',
					},
					{
						ariaLabel: 'Portal',
						columnId: 'portal',
						isSortable: true,
						name: 'Portal',
					},
				],

				columnCurriculums: [
					{
						ariaLabel: 'Sort by Name',
						columnId: 'name',
						isSortable: true,
						name: 'Name',
						width: '300px',
					},
					{
						ariaLabel: 'Payer Source',
						columnId: 'payer_source',
						isSortable: true,
						name: 'Payer Source',
					},
				],

				courseRows: [],
				courses: [],
				curriculumRows: [],
				curriculums: [],
				isLoading: false,
				selectedCertificationRows: [],
				selectedCourseRows: [],
				selectedCurriculumRows: [],
			}
		},

		mounted(): void {
			this.loadCurriculums()
		},

		methods: {
			removeCertificationFromSelectedRows(data: RowData[]): void {
				data.forEach(row => {
					const index = this.selectedCertificationRows.findIndex(selectedRow => selectedRow.rowId === row.rowId)

					if (index > -1) {
						this.selectedCertificationRows.splice(index, 1)
					}
				})
			},

			removeCurriculumFromSelectedRows(data: RowData[]): void {
				data.forEach(row => {
					const index = this.selectedCurriculumRows.findIndex(selectedRow => selectedRow.rowId === row.rowId)

					if (index > -1) {
						this.selectedCurriculumRows.splice(index, 1)
					}
				})
			},

			removeCourseFromSelectedRows(data: RowData[]): void {
				data.forEach(row => {
					const index = this.selectedCourseRows.findIndex(selectedRow => selectedRow.rowId === row.rowId)

					if (index > -1) {
						this.selectedCourseRows.splice(index, 1)
					}
				})
			},

			dataToCellCertificationDataArray(item: Certification): Cell[] {
				return [
					{
						title: {
							dataTest: item.name,
							text: item.name,
						},
					},
					{ title: { text: this.tierText(item.pricing_tier) ?? '' } },
					{ title: { text: releasedAtStatusText(item.released_at) } },
				]
			},

			dataToCellCourseDataArray(item: Course): Cell[] {
				return [
					{
						title: {
							dataTest: item.name,
							text: item.name,
						},
					},
					{ title: { text: item.sku ?? '' } },
					{ title: { text: item.portalName ?? '' } },
				]
			},

			dataToCellCurriculumDataArray(item: Curriculum): Cell[] {
				return [
					{
						title: {
							dataTest: item.name,
							text: item.name,
						},
					},
					{ title: { text: item.payer_source ?? '' } },
				]
			},

			async loadCurriculums(): Promise<void> {
				this.isLoading = true

				try {
					const configElement = document.getElementById('program_management_vue_config')

					if (configElement) {
						const config: any = humps.camelizeKeys(JSON.parse(configElement.innerHTML))

						this.certifications = config.specializedCertifications
						this.courses = config.courses
						this.curriculums = config.curriculums

						// Placing values in the store for future uses/references
						PackagesAndProgramsStore.certifications = this.certifications
						PackagesAndProgramsStore.courses = this.courses
						PackagesAndProgramsStore.curriculums = this.curriculums
					}

					this.certificationRows = this.certifications.map(c => ({
						cells: this.dataToCellCertificationDataArray(c),
						rowId: `${c.id}`,
					}))
					this.selectedCertificationRows = setSelectedRows('program_special_certifications', this.certificationRows)

					this.courseRows = this.courses.map(c => ({
						cells: this.dataToCellCourseDataArray(c),
						rowId: `${c.id}`,
					}))
					this.selectedCourseRows = setSelectedRows('program_courses', this.courseRows)

					this.curriculumRows = this.curriculums.map(c => ({
						cells: this.dataToCellCurriculumDataArray(c),
						rowId: `${c.id}`,
					}))
					this.selectedCurriculumRows = setSelectedRows('program_curriculums', this.curriculumRows)
				} catch (err) {
					console.error(err)
				} finally {
					this.isLoading = false
				}
			},

			tierText(tier: string): string {
				switch (tier) {
					case 'legacy':
						return 'Legacy'

					case 'tier_1':
						return 'T1'

					case 'tier_2':
						return 'T2'

					default:
						return 'T3'
				}
			},

			updateCertificationSelectedRows(data: RowData[]): void {
				this.selectedCertificationRows.push(...data)
			},

			updateCourseSelectedRows(data: RowData[]): void {
				this.selectedCourseRows.push(...data)
			},

			updateCurriculumSelectedRows(data: RowData[]): void {
				this.selectedCurriculumRows.push(...data)
			},
		},
	})
}

interface Data {
	PackagesAndProgramsStore: typeof PackagesAndProgramsStore
	cellCertificationDataArray: string[]
	cellCourseDataArray: string[]
	cellCurriculumDataArray: string[]
	certificationRows: RowData[]
	certifications: Certification[]
	columnCertifications: ColumnData[]
	columnCourses: ColumnData[]
	columnCurriculums: ColumnData[]
	courseRows: RowData[]
	courses: Course[]
	curriculumRows: RowData[]
	curriculums: Curriculum[]
	isLoading: boolean
	selectedCertificationRows: RowData[]
	selectedCourseRows: RowData[]
	selectedCurriculumRows: RowData[]
}
